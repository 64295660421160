@use "../../../../styles/variables.module" as *;
@use "../../../../../../node_modules/react-sortable-tree/style.css";

.treeButton {
  vertical-align: middle;
  background-color: $admin-blue-dark;
  color: white;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 50%;
  margin-left: 3px;
  transition: opacity 300ms;
  font-weight: 600;
  font-size: 1.2rem;

  @media(max-width: $break-point-sm) {
    padding: .4rem .6rem;
  }

  &:hover {
    background-color: $admin-green-dark;
  }
}

.treeButton:hover {
  border: none;
  //box-shadow: -1px -1px $fillgray;
}

.node {
  &Icon {
    padding-right: 10px;
    &Disabled {
      opacity: .7;
    }
  }
  &Disabled {
    font-weight: 100 !important;
    opacity: .7;
  }
  &Title {
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: inherit;
  }
  &Subtitle {
    padding-left: 10px;
    font-weight: 400;

    @media(max-width: $break-point-sm) {
      display: block;
      padding-left: 20px;
    }
  }
  &HiddenIcon {
    padding-left: 10px;
  }
}

.treeNode .treeButton {
  visibility: hidden;
  opacity: 0;

  @media(max-width: $break-point-sm) {
    visibility: visible;
    opacity: 1;
  }
}

.treeNode:hover .treeButton {
  visibility: visible;
  opacity: 1;
}

.container {
  padding: 10px;

  div:focus {
    outline: none;
  }

  &Loading:global { // needs to use global so animation doesn't get converted
    opacity: .5;

    &::after {
      content: "\f110";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      animation: fa-spin 1s steps(8) infinite;
      color: black;
    }
    &::before {
      content: "";
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      z-index: 2;
    }
  }
}
